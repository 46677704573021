import ReactDOM from 'react-dom/client'
import { Authenticator } from '@aws-amplify/ui-react'

import {
  ReactQueryProvider,
  MUIThemeProvider,
  AuthProvider,
} from 'src/presentation'
import bootstrap from './bootstrap'
import App from './App'

bootstrap()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Authenticator.Provider>
    <AuthProvider>
      <ReactQueryProvider>
        <MUIThemeProvider>
          <App />
        </MUIThemeProvider>
      </ReactQueryProvider>
    </AuthProvider>
  </Authenticator.Provider>
)
