import { createContext } from 'react'

import { MSKFormStore } from 'src/infrastructure'

const MSKFormContext = createContext<MSKFormStore>({
  page: 1,
  setPage: () => {},
  step: 1,
  setStep: () => {},
  errors: {},
  setErrors: () => {},
  selectedBotheredBodyPart: '',
  setSelectedBotheredBodyPart: () => {},
  selectedPreviouslyTriedActions: [],
  setSelectedPreviouslyTriedActions: () => {},
  selectedActionsOpenToTry: [],
  setSelectedActionsOpenToTry: () => {},
  howSevereIsYourPain: 0,
  setHowSevereIsYourPain: () => {},
  howMuchDoesThisLimitYourAbilities: 0,
  setHowMuchDoesThisLimitYourAbilities: () => {},
  step2StartQuestion: '',
  setStep2StartQuestion: () => {},
  step2QuestionHistory: [],
  setStep2QuestionHistory: () => {},
  step2Answers: {},
  setStep2Answers: () => {},
  step2Action: '',
  setStep2Action: () => {},
  step2ActionCode: '',
  setStep2ActionCode: () => {},
  step2NextAction: '',
  setStep2NextAction: () => {},
  step2NextQuestion: '',
  setStep2NextQuestion: () => {},
  step3FirstName: '',
  setStep3FirstName: () => {},
  step3LastName: '',
  setStep3LastName: () => {},
  step3Medicare: '',
  setStep3Medicare: () => {},
  step3DateOfBirth: null,
  setStep3DateOfBirth: () => {},
  step3HeightFt: '',
  setStep3HeightFt: () => {},
  step3HeightIn: '',
  setStep3HeightIn: () => {},
  step3Weight: '',
  setStep3Weight: () => {},
  step3HowDoYouPreferToBeContacted: '',
  setStep3HowDoYouPreferToBeContacted: () => {},
  step3ContactConsent: false,
  setStep3ContactConsent: () => {},
  step3Phone: '',
  setStep3Phone: () => {},
  mskProcessResponse: {
    bmi: 0,
    pathway: '',
    pathwayId: '',
    questionPath: {
      botheredBodyPart: '',
      answers: [],
    },
    recommendedAction: '',
    track: '',
  },
  setMskProcessResponse: () => {},
  loadedFormData: {
    patientInfo: {
      firstName: '',
      lastName: '',
      hasConsented: false,
      phone: '',
      provider: '',
      providerStaffEmail: '',
      providerStaffName: '',
    },
    s1: {
      botheredBodyParts: [],
      previouslyTriedActions: [],
      actionsOpenToTry: [],
    },
    s2: {
      actions: {},
      start: {},
      questions: {},
    },
    token: '',
  },
  formType: 'patient',
})
MSKFormContext.displayName = 'MSKFormContext'

export { MSKFormContext }
