import { FC } from 'react'
import { FormProvider, Controller } from 'react-hook-form'

import { RHFTextFieldContainer, SendSmsFormProps } from 'src/presentation'
import {
  Button,
  Stack,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Box,
} from '@mui/material'

export const SendSmsForm: FC<SendSmsFormProps> = ({
  formMethods,
  handleSubmit,
}) => {
  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        data-testid={'send-sms-form'}
      >
        <Stack direction={'column'} rowGap={4}>
          <RHFTextFieldContainer
            name={'firstName'}
            label={"Patient's First Name"}
            variant={'outlined'}
          />
          <RHFTextFieldContainer
            name={'lastName'}
            label={"Patient's Last Name"}
            variant={'outlined'}
          />
          <RHFTextFieldContainer
            name={'phone'}
            label={"Patient's Phone Number"}
            defaultHelperText={
              'Phone number without dashes, spaces or special characters. Example: 12345678901.'
            }
            variant={'outlined'}
          />
          <Controller
            name={'patientConsent'}
            control={formMethods.control}
            render={({ field, fieldState }) => (
              <Box>
                <FormControlLabel
                  componentsProps={{
                    typography: {
                      variant: 'body2',
                    },
                  }}
                  control={
                    <Checkbox
                      {...field}
                      color={'primary'}
                      checked={field.value}
                    />
                  }
                  label={
                    'Patient consents to accepting SMS messages from TailorCare. '
                  }
                />
                <FormHelperText variant={'outlined'} error={!!fieldState.error}>
                  {fieldState.error?.message}
                </FormHelperText>
              </Box>
            )}
          />
          <Button
            type={'submit'}
            variant={'contained'}
            color={'secondary'}
            disabled={formMethods.formState.isSubmitting}
            sx={{ color: 'common.white' }}
          >
            TEXT PATIENT INTAKE FORM TO PATIENT
          </Button>
        </Stack>
      </form>
    </FormProvider>
  )
}
