import { useContext } from 'react'

import { UseBaseLayoutUI, BaseLayoutUIContext } from 'src/infrastructure'

export const useBaseLayoutUI: UseBaseLayoutUI = () => {
  const context = useContext(BaseLayoutUIContext)
  if (!context) {
    throw new Error(
      'useBaseLayoutUI must be used within a BaseLayoutUIContextProvider'
    )
  }
  return context
}
