import { FC } from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
  AppBar,
  IconButton,
  Toolbar,
  Stack,
  Icon,
  Typography,
  Box,
} from '@mui/material'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { NavbarItemProps } from 'src/presentation'

import tcLogo from 'src/assets/images/tc-logo-white.svg'

export const NavbarItem: FC<NavbarItemProps> = ({ to, icon, label }) => {
  return (
    <NavLink
      to={to}
      style={({ isActive }) => ({
        textDecoration: 'none',
        borderRadius: 12,
        backgroundColor: isActive ? 'rgba(146, 229, 218, 0.16)' : 'transparent',
      })}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        textAlign={'center'}
        columnGap={1}
        p={1.5}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Icon>{icon}</Icon>
        {label && (
          <Typography
            variant={'caption'}
            fontFamily={'Inter'}
            fontWeight={500}
            sx={{ color: 'common.white' }}
          >
            {label}
          </Typography>
        )}
      </Stack>
    </NavLink>
  )
}

export const Navbar = () => {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ])

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <AppBar
        sx={{
          backgroundColor: '#004F54',
          borderRadius: '0 0 20px 20px',
          height: '80px',
          position: 'relative',
        }}
      >
        <Toolbar
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack>
            <Link to={'/'}>
              <IconButton>
                <img src={tcLogo} alt="tailorcare logo" height={32} />
              </IconButton>
            </Link>
          </Stack>
          {route !== 'authenticated' ? null : (
            <Stack>
              <Icon
                sx={{
                  fontSize: '35px',
                  color: 'common.white',
                  cursor: 'pointer',
                }}
                onClick={() => signOut()}
              >
                logout
              </Icon>
            </Stack>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}
