import { createContext } from 'react'

import { AuthStore } from 'src/infrastructure'

const AuthContext = createContext<AuthStore>({
  user: null,
  accessToken: undefined,
  idToken: undefined,
  tokensExists: false,
  authIsLoading: false,
  setAuthIsLoading: () => {},
  authError: undefined,
})

AuthContext.displayName = 'AuthContext'

export { AuthContext }
