import { FC } from 'react'
import { TextField } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { RHFTextFieldProps, RHFTextFieldContainerProps } from 'src/presentation'

const RHFTextField: FC<RHFTextFieldProps> = ({
  formMethods,
  name,
  defaultHelperText = '',
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = formMethods

  return (
    <TextField
      {...props}
      error={!!errors[name]}
      helperText={
        errors[name] ? (errors[name]?.message as string) : defaultHelperText
      }
      {...register(name)}
    />
  )
}

export const RHFTextFieldContainer: FC<RHFTextFieldContainerProps> = (
  props
) => {
  const formMethods = useFormContext()
  return <RHFTextField formMethods={formMethods} {...props} />
}
