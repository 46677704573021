import { FC, useMemo, useState } from 'react'

import { MSKFormProviderProps } from 'src/presentation'
import { MSKFormContext } from 'src/infrastructure'
import { MSKFormAnswers, ProcessMSKFormReturns } from 'src/domain'

export const MSKFormProvider: FC<MSKFormProviderProps> = ({
  loadedFormData,
  children,
}) => {
  const formType: 'patient' | 'provider' = loadedFormData.token
    ? 'patient'
    : 'provider'

  const [step, setStep] = useState(1)
  const [page, setPage] = useState(1)
  const [selectedBotheredBodyPart, setSelectedBotheredBodyPart] =
    useState<string>('')
  const [selectedPreviouslyTriedActions, setSelectedPreviouslyTriedActions] =
    useState<string[]>([])
  const [selectedActionsOpenToTry, setSelectedActionsOpenToTry] = useState<
    string[]
  >([])
  const [howSevereIsYourPain, setHowSevereIsYourPain] = useState<number>(5)
  const [
    howMuchDoesThisLimitYourAbilities,
    setHowMuchDoesThisLimitYourAbilities,
  ] = useState<number>(5)
  const [step2StartQuestion, setStep2StartQuestion] = useState<string>('')
  const [step2Answers, setStep2Answers] = useState<MSKFormAnswers>({})
  const [step2Action, setStep2Action] = useState<string>('')
  const [step2ActionCode, setStep2ActionCode] = useState<string>('')
  const [step2NextQuestion, setStep2NextQuestion] = useState<string>('')
  const [step2NextAction, setStep2NextAction] = useState<string>('')
  const [step2QuestionHistory, setStep2QuestionHistory] = useState<string[]>([])
  const [step3FirstName, setStep3FirstName] = useState<string>(
    loadedFormData?.patientInfo?.firstName || ''
  )
  const [step3LastName, setStep3LastName] = useState<string>(
    loadedFormData?.patientInfo?.lastName || ''
  )
  const [step3Medicare, setStep3Medicare] = useState<string>('')
  const [step3DateOfBirth, setStep3DateOfBirth] = useState<Date | null>(null)
  const [step3HeightFt, setStep3HeightFt] = useState<string>('')
  const [step3HeightIn, setStep3HeightIn] = useState<string>('')
  const [step3Weight, setStep3Weight] = useState<string>('')
  const [
    step3HowDoYouPreferToBeContacted,
    setStep3HowDoYouPreferToBeContacted,
  ] = useState<string>('')
  const [step3ContactConsent, setStep3ContactConsent] = useState<boolean>(false)
  const [step3Phone, setStep3Phone] = useState<string>(
    loadedFormData?.patientInfo?.phone || ''
  )
  const [mskProcessResponse, setMskProcessResponse] =
    useState<ProcessMSKFormReturns>({
      bmi: 0,
      pathway: '',
      pathwayId: '',
      questionPath: {
        botheredBodyPart: '',
        answers: [],
      },
      recommendedAction: '',
      track: '',
    })

  const [errors, setErrors] = useState<Record<string, string | null>>({})

  const value = useMemo(
    () => ({
      step,
      setStep,
      page,
      setPage,
      selectedBotheredBodyPart,
      setSelectedBotheredBodyPart,
      selectedPreviouslyTriedActions,
      setSelectedPreviouslyTriedActions,
      selectedActionsOpenToTry,
      setSelectedActionsOpenToTry,
      howSevereIsYourPain,
      setHowSevereIsYourPain,
      howMuchDoesThisLimitYourAbilities,
      setHowMuchDoesThisLimitYourAbilities,
      step2StartQuestion,
      setStep2StartQuestion,
      step2Answers,
      setStep2Answers,
      step2Action,
      setStep2Action,
      step2ActionCode,
      setStep2ActionCode,
      step2NextAction,
      setStep2NextAction,
      step2NextQuestion,
      setStep2NextQuestion,
      step2QuestionHistory,
      setStep2QuestionHistory,
      step3FirstName,
      setStep3FirstName,
      step3LastName,
      setStep3LastName,
      step3Medicare,
      setStep3Medicare,
      step3DateOfBirth,
      setStep3DateOfBirth,
      step3HeightFt,
      setStep3HeightFt,
      step3HeightIn,
      setStep3HeightIn,
      step3Weight,
      setStep3Weight,
      step3HowDoYouPreferToBeContacted,
      setStep3HowDoYouPreferToBeContacted,
      step3ContactConsent,
      setStep3ContactConsent,
      step3Phone,
      setStep3Phone,
      errors,
      setErrors,
      mskProcessResponse,
      setMskProcessResponse,
      loadedFormData,
      formType,
    }),
    [
      step,
      page,
      selectedBotheredBodyPart,
      selectedPreviouslyTriedActions,
      selectedActionsOpenToTry,
      howSevereIsYourPain,
      howMuchDoesThisLimitYourAbilities,
      step2StartQuestion,
      step2Answers,
      step2Action,
      step2ActionCode,
      step2NextAction,
      step2NextQuestion,
      step2QuestionHistory,
      step3FirstName,
      step3LastName,
      step3Medicare,
      step3DateOfBirth,
      step3HeightFt,
      step3HeightIn,
      step3Weight,
      step3HowDoYouPreferToBeContacted,
      step3ContactConsent,
      step3Phone,
      mskProcessResponse,
      errors,
      loadedFormData,
      formType,
    ]
  )
  return (
    <MSKFormContext.Provider value={value}>{children}</MSKFormContext.Provider>
  )
}
