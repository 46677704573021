import { format as formatDate } from 'date-fns'

import {
  MapToLoadMSKPatientFormServicePayload,
  MapToLoadMSKPatientFormError,
  MapToLoadMSKPatientFormReturns,
  MapToLoadMSKProviderFormError,
  MapToLoadMSKProviderFormReturns,
  MapToProcessMSKFormArgs,
  MapToProcessMSKFormError,
  MapToProcessMSKFormReturns,
  MapToProcessMSKFormServicePayload,
} from 'src/adapters'

export const mapToLoadMSKPatientFormServicePayload: MapToLoadMSKPatientFormServicePayload =
  (args) => {
    return {
      tcId: args.tcId,
    }
  }

export const mapToLoadMSKPatientFormError: MapToLoadMSKPatientFormError = (
  response
) => {
  return {
    message: response.message,
  }
}

export const mapToLoadMSKPatientFormReturns: MapToLoadMSKPatientFormReturns = (
  response
) => {
  return {
    token: response.token,
    patientInfo: response.patientInfo,
    s1: response.s1,
    s2: response.s2,
  }
}

export const mapToLoadMSKProviderFormError: MapToLoadMSKProviderFormError = (
  response
) => {
  return {
    message: response.message,
  }
}

export const mapToLoadMSKProviderFormReturns: MapToLoadMSKProviderFormReturns =
  (response) => {
    return {
      s1: response.s1,
      s2: response.s2,
    }
  }

export const mapToProcessMSKFormError: MapToProcessMSKFormError = (
  response
) => {
  return {
    message: response.message,
  }
}

export const mapToProcessMSKFormReturns: MapToProcessMSKFormReturns = (
  response
) => {
  return {
    ...response,
  }
}

export const mapToProcessMSKFormServicePayload: MapToProcessMSKFormServicePayload =
  (args) => {
    return {
      s1: args.s1,
      s2: args.s2,
      s3: args.s3,
    }
  }

export const mapToProcessMSKFormArgs: MapToProcessMSKFormArgs = (
  store,
  hardstop
) => {
  const getHeightInInches = (feet: number, inches: number) =>
    (feet * 12 + inches).toString()

  const formatDateOfBirth = (date: Date) => formatDate(date, 'MM/dd/yyyy')
  const firstName = store.step3FirstName || 'hardstop'

  const lastName = store.step3LastName || 'hardstop'

  const dateOfBirth = store.step3DateOfBirth
    ? formatDateOfBirth(store.step3DateOfBirth)
    : '01/01/1947'

  const height =
    store.step3HeightFt || store.step3HeightIn
      ? getHeightInInches(
          Number(store.step3HeightFt),
          Number(store.step3HeightIn)
        )
      : '0'

  const weight = store.step3Weight || '0'

  const preferredCommunication =
    store.step3HowDoYouPreferToBeContacted || 'phone'

  const phone = store.step3Phone || '3333333333'

  const medicareAdvantageMemberId = hardstop
    ? store.step3Medicare || 'hardstop'
    : store.step3Medicare

  const step3Answers = {
    firstName,
    lastName,
    dateOfBirth,
    height,
    weight,
    preferredCommunication,
    phone,
    medicareAdvantageMemberId,
  }

  return {
    s1: {
      botheredBodyPart: store.selectedBotheredBodyPart,
      currentPainLevel: store.howSevereIsYourPain,
      abilityToPerformDailyActivities: store.howMuchDoesThisLimitYourAbilities,
    },
    s2: {
      questions: store.step2Answers,
      recommendedAction: store.step2ActionCode,
    },
    s3: step3Answers,
  }
}
