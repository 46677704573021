import { createTheme } from '@mui/material'

import { tcColors } from './tc-color-palette'

export const muiTheme = createTheme({
  palette: {
    grey: {
      50: tcColors.grey[50],
      100: tcColors.grey[100],
      200: tcColors.grey[200],
    },
    navy: {
      50: tcColors.navy[50],
      100: tcColors.navy[100],
      200: tcColors.navy[200],
    },
    green: {
      50: tcColors.green[50],
      100: tcColors.green[100],
      200: tcColors.green[200],
      300: tcColors.green[300],
      400: tcColors.green[400],
    },
    teal: {
      50: tcColors.teal[50],
      100: tcColors.teal[100],
      200: tcColors.teal[200],
      300: tcColors.teal[300],
      400: tcColors.teal[400],
    },
    lime: {
      50: tcColors.lime[50],
      100: tcColors.lime[100],
      200: tcColors.lime[200],
      300: tcColors.lime[300],
      400: tcColors.lime[400],
    },
    magenta: {
      50: tcColors.magenta[50],
      100: tcColors.magenta[100],
      200: tcColors.magenta[200],
      300: tcColors.magenta[300],
      400: tcColors.magenta[400],
    },
    pink: {
      50: tcColors.pink[50],
      100: tcColors.pink[100],
      200: tcColors.pink[200],
      300: tcColors.pink[300],
      400: tcColors.pink[400],
    },
    primary: {
      main: tcColors.teal[300],
      light: tcColors.teal[200],
      dark: tcColors.teal[400],
      contrastText: 'palette.common.white',
    },
    secondary: {
      main: tcColors.magenta[300],
      light: tcColors.magenta[200],
      dark: tcColors.magenta[400],
      contrastText: 'palette.common.white',
    },
    neutral: {
      grey: {
        main: tcColors.grey[100],
        light: tcColors.grey[50],
        dark: tcColors.grey[200],
        contrastText: 'palette.common.white',
      },
      navy: {
        main: tcColors.navy[100],
        light: tcColors.navy[50],
        dark: tcColors.navy[200],
        contrastText: 'palette.common.white',
      },
    },
  },
  components: {
    MuiIcon: {
      defaultProps: {
        // Replace the `material-icons` default value.
        baseClassName: 'material-icons-outlined',
        sx: {
          color: 'common.white',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            color: 'white',
          },
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          backgroundColor: '#E2EAEB',
        },
        mark: {
          backgroundColor: '#00454A',
        },
        markLabel: {
          color: 'black',
          fontSize: '1rem',
          fontWeight: 500,
        },
      },
    },
  },
})
