import { makePTCUseCases, makePTAIFUseCases } from 'src/domain'
import {
  usePTAIFPatientAdapter,
  usePTAIFProviderAdapter,
  usePTCAdapter,
} from 'src/adapters'
import { init as PTCServiceLocatorInit } from 'src/features/ptc/service-locator'
import { init as PTAIFServiceLocatorInit } from 'src/features/ptaif/service-locator'

export const serviceLocatorInit = () => {
  const usePTCUseCases = makePTCUseCases({
    usePTCUseCases: usePTCAdapter,
  })
  PTCServiceLocatorInit({ usePTCUseCases })

  const { usePTAIFPatientUseCases, usePTAIFProviderUseCases } =
    makePTAIFUseCases({
      usePTAIFPatientUseCases: usePTAIFPatientAdapter,
      usePTAIFProviderUseCases: usePTAIFProviderAdapter,
    })
  PTAIFServiceLocatorInit({ usePTAIFPatientUseCases, usePTAIFProviderUseCases })
}
