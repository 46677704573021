import axios from 'axios'
import { useMemo } from 'react'

import { useAuth, UseClientWithAuth } from 'src/infrastructure'

export const useClientWithAuth: UseClientWithAuth = () => {
  const { accessToken, idToken } = useAuth()

  const clientWithAuth = useMemo(() => {
    const axiosClient = axios.create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return axiosClient
  }, [accessToken])

  return {
    clientWithAuth,
    accessToken,
    idToken,
  }
}
