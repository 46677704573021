import { FC } from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

import { useBaseLayoutUI } from 'src/infrastructure'

export const FullPageloader: FC = () => {
  const { showFullPageLoader } = useBaseLayoutUI()
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showFullPageLoader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
