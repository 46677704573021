import { Amplify } from 'aws-amplify'

import { serviceLocatorInit } from './service-locator'
import { worker } from './__mocks__/browser'
import { amplifyConfig } from './config'
import * as settings from './config/settings'

export default () => {
  Amplify.configure(amplifyConfig)

  serviceLocatorInit()

  if (settings.MOCK_BACKEND) {
    worker.start()
  }
}
