import { FC } from 'react'

import { Box, Stack, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'

export const HomePage: FC = () => {
  return (
    <Box>
      <Stack direction={'column'} rowGap={3}>
        <Typography
          variant={'h1'}
          sx={{ fontSize: '2rem', fontWeight: '400', color: '#16C9B1' }}
        >
          TailorCare supports Primary Care Providers by serving as a
          comprehensive destination for all MSK referrals
        </Typography>
        <Typography variant={'body1'}>
          We route patients to the appropriate care using evidence-based
          guidelines and offer patient-centered support throughout the patient’s
          care journey. TailorCare makes caring for MSK patients easier with
          coordinated, connected care from diagnosis through treatment and
          beyond.
        </Typography>
        <Typography variant={'body1'}>
          You can complete the form with the patient on the phone or choose to
          send the patient a text (SMS) with a link to the intake assessment
          that can be completed independently.
        </Typography>
      </Stack>
      <Box sx={{ height: '6rem' }} />
      <Stack direction={'row'} justifyContent={'center'} spacing={8}>
        <Link to={'/ptaif'} style={{ textDecoration: 'none' }}>
          <Button
            variant={'outlined'}
            color={'secondary'}
            data-testid={'ptaif_home_button'}
          >
            INTAKE ASSESSMENT
          </Button>
        </Link>
        <Link to={'/ptc'} style={{ textDecoration: 'none' }}>
          <Button variant={'outlined'} color={'secondary'}>
            SEND SMS TO PATIENT
          </Button>
        </Link>
      </Stack>
    </Box>
  )
}
