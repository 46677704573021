import { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import CopyToClipboard from 'react-copy-to-clipboard'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { TCCopyToClipboardProps } from 'src/presentation'

export const TCCopyToClipboard: FC<TCCopyToClipboardProps> = ({
  value,
  tooltipOpen,
  onCopy,
  onTooltipClose,
}) => {
  return (
    <CopyToClipboard text={value} onCopy={onCopy}>
      <Tooltip
        open={tooltipOpen}
        onClose={onTooltipClose}
        title="Copied!"
        disableHoverListener
      >
        <IconButton sx={{ padding: 0 }}>
          <ContentCopyIcon sx={{ width: '16px' }} />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  )
}
