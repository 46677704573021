import { FC } from 'react'
import { Box, Typography } from '@mui/material'

export const ErrorPage: FC = () => {
  return (
    <Box>
      <Typography>Oops!</Typography>
      <Typography>Sorry, an unexpected error has occurred.</Typography>
    </Box>
  )
}
