import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { Authenticator } from '@aws-amplify/ui-react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { I18n } from 'aws-amplify'
import { Box, Paper } from '@mui/material'

import {
  AmplifyUIReactThemeProvider,
  amplifyUiCustomComponents,
  amplifyCustomFormFields,
} from 'src/presentation'

import '@aws-amplify/ui-react/styles.css'
import './custom-amplify-ui.css'

I18n.putVocabulariesForLanguage('en', {
  'Sign in': 'LOGIN', // Button label
})

export const GoogleLoginFormPage = () => {
  const { route } = useAuthenticator((context) => [context.route])
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || '/'
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true })
    }
  }, [route, navigate, from])
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Paper sx={{ pb: 3, px: 2, maxWidth: '350px' }}>
        <AmplifyUIReactThemeProvider>
          <Authenticator
            components={amplifyUiCustomComponents}
            formFields={amplifyCustomFormFields}
            socialProviders={['google']}
          ></Authenticator>
        </AmplifyUIReactThemeProvider>
      </Paper>
    </Box>
  )
}
