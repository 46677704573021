import { useMemo } from 'react'
import { QueryKey, useQuery } from '@tanstack/react-query'

import { APIError } from 'src/adapters'
import {
  LoadMSKPatientFormReturns,
  LoadMSKProviderFormReturns,
} from 'src/domain'
import { UseLoadMSKPatientFormProps } from 'src/presentation'
import {
  usePTAIFPatientUseCases,
  usePTAIFProviderUseCases,
} from 'src/service-locator'

export const LOAD_MSK_PATIENT_FORM_QUERY_KEY = 'loadMSKPatientForm'
export const LOAD_MSK_PROVIDER_FORM_QUERY_KEY = 'loadMSKProviderForm'

export const useLoadMSKPatientForm = ({ tcId }: UseLoadMSKPatientFormProps) => {
  const { loadMSKPatientForm } = usePTAIFPatientUseCases()
  const queryKey = useMemo<QueryKey>(
    () => [LOAD_MSK_PATIENT_FORM_QUERY_KEY, tcId],
    [tcId]
  )
  const {
    data: loadedMSKFormData,
    isError: loadMSKPatientFormIsError,
    isSuccess: loadMSKPatientFormIsSuccess,
  } = useQuery<LoadMSKPatientFormReturns, APIError>(queryKey, () =>
    loadMSKPatientForm({ tcId })
  )
  return {
    loadedMSKFormData,
    loadMSKPatientFormIsError,
    loadMSKPatientFormIsSuccess,
  }
}

export const useLoadMSKProviderForm = () => {
  const { loadMSKProviderForm } = usePTAIFProviderUseCases()
  const {
    data: loadedMSKFormData,
    isError: loadMSKProviderFormIsError,
    isSuccess: loadMSKProviderFormIsSuccess,
  } = useQuery<LoadMSKProviderFormReturns, APIError>(
    [LOAD_MSK_PATIENT_FORM_QUERY_KEY],
    () => loadMSKProviderForm()
  )
  return {
    loadedMSKFormData,
    loadMSKProviderFormIsError,
    loadMSKProviderFormIsSuccess,
  }
}
