import { Theme } from '@aws-amplify/ui-react'

import { tcColors } from './tc-color-palette'

export const amplifyUIReactTheme: Theme = {
  name: 'tailor-care-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          '10': { value: tcColors.teal[50] },
          '20': { value: tcColors.teal[100] },
          '40': { value: tcColors.teal[200] },
          '60': { value: tcColors.teal[300] },
          '80': { value: tcColors.teal[300] },
          '90': { value: tcColors.teal[400] },
          '100': { value: tcColors.teal[400] },
        },
        secondary: {
          '10': { value: tcColors.magenta[50] },
          '20': { value: tcColors.magenta[100] },
          '40': { value: tcColors.magenta[200] },
          '60': { value: tcColors.magenta[300] },
          '80': { value: tcColors.magenta[300] },
          '90': { value: tcColors.magenta[400] },
          '100': { value: tcColors.magenta[400] },
        },
      },
    },
    components: {
      authenticator: {
        container: {
          widthMax: { value: '100%' },
        },
      },
      button: {
        _loading: {
          color: { value: 'white' },
        },
        primary: {
          backgroundColor: {
            value: 'var(--amplify-colors-brand-secondary-60)',
          },
          _loading: {
            backgroundColor: {
              value: 'var(--amplify-colors-brand-secondary-60)',
            },
          },
          _focus: {
            backgroundColor: {
              value: 'var(--amplify-colors-brand-secondary-60)',
            },
            borderColor: {
              value: 'var(--amplify-colors-brand-secondary-60)',
            },
            boxShadow: {
              value: '0 0 0 3px var(--amplify-colors-brand-secondary-60)',
            },
          },
          _hover: {
            backgroundColor: {
              value: 'var(--amplify-colors-brand-secondary-100)',
            },
          },
          _active: {
            backgroundColor: {
              value: 'var(--amplify-colors-brand-secondary-100)',
            },
            borderColor: {
              value: 'var(--amplify-colors-brand-secondary-100)',
            },
          },
        },
      },
    },
  },
}
