const palette = {
  grey: {
    50: '#F9E3EF',
    100: '#CFD1D4',
    200: '#868789',
  },
  navy: {
    50: '#8D929F',
    100: '#40588D',
    200: '#303B53',
  },
  green: {
    50: '#E2EAEB',
    100: '#8CB0B2',
    200: '#40787B',
    300: '#00454A',
    400: '#003033',
  },
  teal: {
    50: '#E3F9F6',
    100: '#92E5DA',
    200: '#44D3C0',
    300: '#17C9B0',
    400: '#009682',
  },
  lime: {
    50: '#EFFDF6',
    100: '#BEF5DC',
    200: '#8EEDC2',
    300: '#78E8B2',
    400: '#4AB284',
  },
  magenta: {
    50: '#F9E3EF',
    100: '#EEADCF',
    200: '#DD60A1',
    300: '#C40A73',
    400: '#820B47',
  },
  pink: {
    50: '#FFE2EA',
    100: '#FFAAC3',
    200: '#FF5C8B',
    300: '#FF004F',
    400: '#CB1039',
  },
}

const neutralPalette = {
  light: palette.grey[100],
  dark: palette.navy[200],
}

export const tcColors = {
  ...palette,
  ...neutralPalette,
}
