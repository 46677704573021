import {
  MapToSendSMSError,
  MapToSendSMSReturns,
  MapToSendSMSServicePayload,
} from 'src/adapters'

export const mapToSendSMSServicePayload: MapToSendSMSServicePayload = (
  args
) => {
  return {
    phone: args.phone,
    firstName: args.firstName,
    lastName: args.lastName,
    hasConsented: args.hasConsented,
  }
}

export const mapToSendSMSError: MapToSendSMSError = (response) => {
  return {
    message: response.message,
  }
}

export const mapToSendSMSReturns: MapToSendSMSReturns = (response) => {
  return {
    message: response.message,
  }
}
