import { rest } from 'msw'

import {
  SendSMSServiceResponse,
  SendSMSServicePayload,
} from 'src/infrastructure'

import * as settings from 'src/config/settings'

const delay = process.env.NODE_ENV === 'test' ? 0 : 5000

const url = `${settings.SEND_SMS_API_BASE_URL}/send-sms`

export const sendSMSHandler = rest.post<
  SendSMSServicePayload,
  SendSMSServiceResponse
>(url, async (req, res, ctx) => {
  const { phone } = await req.json()

  if (phone == '10999888777') {
    return res(
      ctx.status(400),
      ctx.delay(delay),
      ctx.json({
        message: 'Phone number is invalid',
      })
    )
  }

  if (phone == '11999888777') {
    return res(
      ctx.status(401),
      ctx.delay(delay),
      ctx.json({
        message: 'Unathorized',
      })
    )
  }

  return res(
    ctx.status(200),
    ctx.delay(delay),
    ctx.json({
      message: 'Successfully sent an SMS to patient.',
    })
  )
})
