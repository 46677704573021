import { useCallback, useMemo } from 'react'

import {
  SendSMSServicePayload,
  SendSMSServiceResponse,
  useClientWithAuth,
} from 'src/infrastructure'

import * as settings from 'src/config/settings'

export const useSendSMSService = () => {
  const { clientWithAuth, idToken } = useClientWithAuth()

  const client = useMemo(() => {
    clientWithAuth.defaults.baseURL = settings.SEND_SMS_API_BASE_URL
    return clientWithAuth
  }, [clientWithAuth])

  const sendSMS = useCallback(
    (payload: SendSMSServicePayload) => {
      return client.post<SendSMSServiceResponse>(
        `/send-sms?idToken=${idToken}`,
        payload
      )
    },
    [client, idToken]
  )

  return {
    sendSMS,
  }
}
