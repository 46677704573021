import axios from 'axios'

import { PTAIFPatientUseCases, PTAIFProviderUseCases } from 'src/domain'
import {
  UsePTAIFPatientAdapter,
  UsePTAIFProviderAdapter,
  mapToLoadMSKPatientFormServicePayload,
  mapToLoadMSKPatientFormError,
  mapToLoadMSKPatientFormReturns,
  mapToLoadMSKProviderFormError,
  mapToLoadMSKProviderFormReturns,
  mapToProcessMSKFormServicePayload,
  mapToProcessMSKFormError,
  mapToProcessMSKFormReturns,
  APIError,
} from 'src/adapters'
import {
  useLoadMSKPatientFormSevice,
  useLoadMSKProviderFormSevice,
  useProcessMSKPatientFormService,
  useProcessMSKProviderFormService,
  LoadMSKPatientFormServiceErrorResponse,
  LoadMSKProviderFormServiceErrorResponse,
  ProcessMSKFormServiceErrorResponse,
} from 'src/infrastructure'

export const usePTAIFPatientAdapter: UsePTAIFPatientAdapter = () => {
  const loadMSKPatientFormService = useLoadMSKPatientFormSevice()
  const processMSKPatientFormService = useProcessMSKPatientFormService()

  const loadMSKPatientForm: PTAIFPatientUseCases['loadMSKPatientForm'] = async (
    args
  ) => {
    try {
      const payload = mapToLoadMSKPatientFormServicePayload(args)
      const response = await loadMSKPatientFormService.loadMSKPatientForm(
        payload
      )
      return mapToLoadMSKPatientFormReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const loadMSKPatientFormErrorResponse = error.response?.data
        if (loadMSKPatientFormErrorResponse) {
          const loadMSKPatientFormError = mapToLoadMSKPatientFormError(
            loadMSKPatientFormErrorResponse as LoadMSKPatientFormServiceErrorResponse
          )
          throw new APIError(loadMSKPatientFormError.message)
        }
      }
      throw error
    }
  }

  const processMSKPatientForm: PTAIFPatientUseCases['processMSKPatientForm'] =
    async (authToken, args) => {
      try {
        const payload = mapToProcessMSKFormServicePayload(args)
        const response =
          await processMSKPatientFormService.processMSKPatientForm(
            authToken,
            payload
          )
        return mapToProcessMSKFormReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const processMSKFormErrorResponse = error.response?.data
          if (processMSKFormErrorResponse) {
            const processMSKFormError = mapToProcessMSKFormError(
              processMSKFormErrorResponse as ProcessMSKFormServiceErrorResponse
            )
            throw new APIError(processMSKFormError.message)
          }
        }
        throw error
      }
    }

  return {
    loadMSKPatientForm,
    processMSKPatientForm,
  }
}

export const usePTAIFProviderAdapter: UsePTAIFProviderAdapter = () => {
  const loadMSKProviderFormService = useLoadMSKProviderFormSevice()
  const processMSKProviderFormService = useProcessMSKProviderFormService()

  const loadMSKProviderForm: PTAIFProviderUseCases['loadMSKProviderForm'] =
    async () => {
      try {
        const response = await loadMSKProviderFormService.loadMSKProviderForm()
        return mapToLoadMSKProviderFormReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const loadMSKProviderFormErrorResponse = error.response?.data
          if (loadMSKProviderFormErrorResponse) {
            const loadMSKProviderFormError = mapToLoadMSKProviderFormError(
              loadMSKProviderFormErrorResponse as LoadMSKProviderFormServiceErrorResponse
            )
            throw new APIError(loadMSKProviderFormError.message)
          }
        }
        throw error
      }
    }

  const processMSKProviderForm: PTAIFProviderUseCases['processMSKProviderForm'] =
    async (args) => {
      try {
        const payload = mapToProcessMSKFormServicePayload(args)
        const response =
          await processMSKProviderFormService.processMSKProviderForm(payload)
        return mapToProcessMSKFormReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const processMSKFormErrorResponse = error.response?.data
          if (processMSKFormErrorResponse) {
            const processMSKFormError = mapToProcessMSKFormError(
              processMSKFormErrorResponse as ProcessMSKFormServiceErrorResponse
            )
            throw new APIError(processMSKFormError.message)
          }
        }
        throw error
      }
    }

  return {
    loadMSKProviderForm,
    processMSKProviderForm,
  }
}
