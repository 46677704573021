import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Box, Stack } from '@mui/material'

import { toastContainerConfig } from 'src/config'
import {
  Navbar,
  BaseLayoutUIProvider,
  FullPageloader,
  ChildrenInProps,
} from 'src/presentation'

import 'react-toastify/dist/ReactToastify.min.css'

export const BaseLayoutWrapper: FC<ChildrenInProps> = ({ children }) => {
  return (
    <BaseLayoutUIProvider>
      <Box id={'main-box-base-layout'}>
        <Stack id={'main-stack-base-layout'} minHeight={'100vh'}>
          <Navbar />
          <Box
            id={'first-box-wrapper-outlet-base-layout'}
            sx={{
              boxSizing: 'border-box',
              minHeight: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexGrow: 1,
            }}
          >
            <Box
              id={'second-box-wrapper-outlet-base-layout'}
              sx={{
                boxSizing: 'border-box',
                pt: {
                  xs: '4rem',
                  md: '4rem',
                },
                pb: {
                  xs: '2rem',
                },
                px: {
                  xs: '1rem',
                },
                width: {
                  xs: '100%',
                  md: '680px',
                },
              }}
            >
              {children}
            </Box>
          </Box>
        </Stack>
        <ToastContainer {...toastContainerConfig} />
        <FullPageloader />
      </Box>
    </BaseLayoutUIProvider>
  )
}

export const RouterBaseLayout: FC = () => {
  return (
    <BaseLayoutWrapper>
      <Outlet />
    </BaseLayoutWrapper>
  )
}
