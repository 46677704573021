import { RouterProvider } from 'react-router-dom'

import { OktaLoginFormPage } from 'src/presentation'
import router from 'src/router'

import { useAuth } from './infrastructure'

function App() {
  const { tokensExists } = useAuth()
  return (
    <>
      {tokensExists ? (
        <RouterProvider router={router} />
      ) : (
        <OktaLoginFormPage />
      )}
    </>
  )
}

export default App
