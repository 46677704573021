import { FC, useState, useMemo, useEffect } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { ChildrenInProps, BackdropLoader } from 'src/presentation'
import { AuthContext } from 'src/infrastructure'
import { Hub } from 'aws-amplify'

export const AuthProvider: FC<ChildrenInProps> = ({ children }) => {
  const [authIsLoading, setAuthIsLoading] = useState(false)
  const [authError, setAuthError] = useState<string | undefined>()
  const { user } = useAuthenticator((context) => [context.user])

  const accessToken = useMemo(() => {
    return user
      ? user.getSignInUserSession()?.getAccessToken().getJwtToken()
      : undefined
  }, [user])

  const idToken = useMemo(() => {
    return user
      ? user.getSignInUserSession()?.getIdToken().getJwtToken()
      : undefined
  }, [user])

  const tokensExists = useMemo(() => {
    return Boolean(accessToken && idToken)
  }, [accessToken, idToken])

  const value = useMemo(
    () => ({
      user,
      accessToken,
      idToken,
      tokensExists,
      authIsLoading,
      setAuthIsLoading,
      authError,
    }),
    [user, accessToken, idToken, tokensExists, authIsLoading, authError]
  )

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'signIn_failure') {
        setAuthError(data.message)
      }
      switch (event) {
        case 'signIn':
          setAuthIsLoading(false)
          break
        case 'signIn_failure':
          setAuthError(data.message)
          setAuthIsLoading(false)
          break
      }
    })

    return unsubscribe
  }, [])

  return (
    <AuthContext.Provider value={value}>
      <>
        {children}
        <BackdropLoader open={authIsLoading} />
      </>
    </AuthContext.Provider>
  )
}
