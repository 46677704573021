/* eslint-disable */
import * as settings from 'src/config/settings'

export const amplifyConfig = {
  aws_project_region: settings.AWS_COGNITO_REGION,
  aws_cognito_identity_pool_id: settings.AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: settings.AWS_COGNITO_REGION,
  aws_user_pools_id: settings.AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: settings.AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: settings.AWS_OAUTH_DOMAIN,
    scope: settings.AWS_OAUTH_SCOPE,
    redirectSignIn: settings.AWS_OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut: settings.AWS_OAUTH_REDIRECT_SIGN_OUT,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
}
