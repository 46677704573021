import { MSKFormAnswers, MSKFormStep2 } from 'src/domain'

export const getStep2Question = (
  id: string,
  questions: MSKFormStep2['questions']
) => questions[id] || null

export const getStep2Action = (id: string, actions: MSKFormStep2['actions']) =>
  actions[id] || null

export const isAStep2QuestionId = (id?: string) => id && id.endsWith('q')

export const isAStep2ActionId = (id?: string) => id && id.endsWith('a')

export const getStep2CurrentAnswer = (
  step2Answers: MSKFormAnswers,
  questionId?: string
) => {
  if (!questionId) {
    return ''
  }
  return step2Answers[questionId] ? step2Answers[questionId].answer : ''
}
