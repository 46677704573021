import { FC } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'

import { muiTheme, ChildrenInProps } from 'src/presentation'

export const MUIThemeProvider: FC<ChildrenInProps> = ({ children }) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
