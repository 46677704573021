import { setupWorker } from 'msw'

import { sendSMSHandler } from './handlers'

import * as settings from 'src/config/settings'

const handlers = []

if (settings.MOCK_SEND_SMS_API) {
  handlers.push(sendSMSHandler)
}

export const worker = setupWorker(...handlers)
