import { FC, useState, useEffect, useMemo } from 'react'
import { useIsMutating, useIsFetching } from '@tanstack/react-query'

import { ChildrenInProps } from 'src/presentation'
import { BaseLayoutUIContext } from 'src/infrastructure'

export const BaseLayoutUIProvider: FC<ChildrenInProps> = ({ children }) => {
  const [showFullPageLoader, setShowFullPageLoader] = useState(false)
  const isMutating = useIsMutating()
  const isFetching = useIsFetching()

  const value = useMemo(
    () => ({ showFullPageLoader, setShowFullPageLoader }),
    [showFullPageLoader, setShowFullPageLoader]
  )

  useEffect(() => {
    setShowFullPageLoader(!!isMutating)
  }, [isMutating])

  useEffect(() => {
    setShowFullPageLoader(!!isFetching)
  }, [isFetching])

  return (
    <BaseLayoutUIContext.Provider value={value}>
      {children}
    </BaseLayoutUIContext.Provider>
  )
}
