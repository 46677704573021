import { createBrowserRouter } from 'react-router-dom'

import { ErrorPage } from 'src/presentation'

import { RequireAuth } from './components'
import RouterBaseLayout from './layouts/base'
import HomePage from './routes/home'
import SendSMSFormPage from './routes/ptc'
import PTAIFPage from './routes/ptaif'
import PatientPTAIFPage from './routes/patient-ptaif'

export const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    element: <RouterBaseLayout />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: (
              <RequireAuth>
                <HomePage />
              </RequireAuth>
            ),
          },
          {
            path: 'ptc',
            element: (
              <RequireAuth>
                <SendSMSFormPage />
              </RequireAuth>
            ),
          },
          {
            path: 'ptaif',
            element: (
              <RequireAuth>
                <PTAIFPage />
              </RequireAuth>
            ),
          },
          {
            path: '/patient-ptaif',
            element: <PatientPTAIFPage />,
          },
        ],
      },
    ],
  },
])
