import { FC } from 'react'
import { SchemaOf, string, bool, object } from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { Box, Stack, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'

import { usePTCUseCases } from 'src/service-locator'
import { SendSMSFormFields, SendSmsForm } from 'src/presentation'
import { SendSMSArgs, SendSMSReturns } from 'src/domain'
import { APIError } from 'src/adapters'

const sendSMSFormSchema: SchemaOf<SendSMSFormFields> = object({
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  phone: string()
    .matches(/^[1-9]\d{1,14}$/, 'Invalid phone')
    .required('Phone is required'),
  patientConsent: bool()
    .oneOf([true], "Patient's consent is required")
    .default(false),
})

export const SendSMSFormPage: FC = () => {
  const PTCUseCases = usePTCUseCases()

  const formMethods = useForm<SendSMSFormFields>({
    resolver: yupResolver(sendSMSFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      patientConsent: false,
    },
  })

  const { mutate: sendSMS } = useMutation<
    SendSMSReturns,
    APIError,
    SendSMSArgs
  >(PTCUseCases.sendSMS, {
    onSuccess: () => {
      toast.success('SMS sent successfully')
      formMethods.reset()
    },
    onError: () => {
      toast.error('Error sending SMS', { autoClose: false })
    },
  })

  const submitSendSMSForm: SubmitHandler<SendSMSFormFields> = async (data) => {
    sendSMS({
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      hasConsented: data.patientConsent,
    })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '48px' }}>
      <Stack direction={'column'} rowGap={3}>
        <Typography
          variant={'h1'}
          color={'primary'}
          sx={{ fontSize: '2rem', fontWeight: '500' }}
        >
          It&apos;s Easy to Get Your Patients Connected With TailorCare
        </Typography>
        <Typography variant={'body1'}>
          TailorCare is an individualized care program to support your patient's
          joint, bone, and muscle health. We empower patients to manage their
          own health and improve their outcomes.
        </Typography>
      </Stack>
      <Stack direction={'column'} rowGap={3}>
        <Typography
          variant={'h2'}
          sx={{ fontSize: '1.5rem', color: 'green.300', fontWeight: '500' }}
        >
          Send Your Patient the Intake Assessment
        </Typography>
        <Typography variant={'body1'}>
          Our evidence-based intake assessment will help route your patients to
          the appropriate level of care and offer patient-centered support
          throughout their care journey, all while keeping you in the loop. Use
          the short form below send the link to your patient now.
        </Typography>
      </Stack>
      <Stack sx={{ maxWidth: '400px' }}>
        <SendSmsForm
          formMethods={formMethods}
          handleSubmit={submitSendSMSForm}
        />
      </Stack>
      <DevTool control={formMethods.control} />
    </Box>
  )
}
