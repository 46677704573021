import {
  UsePTAIFPatientUseCases,
  UsePTAIFProviderUseCases,
  PTAIFServiceLocatorInit,
} from 'src/domain'

export let usePTAIFPatientUseCases: UsePTAIFPatientUseCases
export let usePTAIFProviderUseCases: UsePTAIFProviderUseCases

export const init: PTAIFServiceLocatorInit = (props) => {
  usePTAIFPatientUseCases = props.usePTAIFPatientUseCases
  usePTAIFProviderUseCases = props.usePTAIFProviderUseCases
}
