import { FC } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { reactQueryClientConfig } from 'src/config'
import { ChildrenInProps } from 'src/presentation'

const queryClient = new QueryClient(reactQueryClientConfig)

export const ReactQueryProvider: FC<ChildrenInProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
