import { FC, useEffect } from 'react'
import { AppBar, Box, IconButton, Paper, Stack, Toolbar } from '@mui/material'
import { Image } from '@aws-amplify/ui-react'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

import { SignInButton, BackdropLoader } from 'src/presentation'

import { useAuth } from 'src/infrastructure'

import tcHorizontalLogo from 'src/assets/images/tc-horizontal-logo.svg'
import tcLogo from 'src/assets/images/tc-logo-white.svg'

export const OktaLoginFormPage: FC = () => {
  const { authIsLoading, setAuthIsLoading } = useAuth()
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const code = searchParams.get('code')
    if (code) {
      setAuthIsLoading(true)
    }
  }, [setAuthIsLoading])
  return (
    <Box id={'main-box-base-layout'}>
      <Stack id={'main-stack-base-layout'} minHeight={'100vh'}>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <AppBar
            sx={{
              backgroundColor: '#004F54',
              borderRadius: '0 0 20px 20px',
              height: '80px',
              position: 'relative',
            }}
          >
            <Toolbar
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Stack>
                <IconButton>
                  <img src={tcLogo} alt="tailorcare logo" height={32} />
                </IconButton>
              </Stack>
            </Toolbar>
          </AppBar>
        </Box>
        <Box
          id={'first-box-wrapper-outlet-base-layout'}
          sx={{
            boxSizing: 'border-box',
            minHeight: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
          }}
        >
          <Box
            id={'second-box-wrapper-outlet-base-layout'}
            sx={{
              boxSizing: 'border-box',
              pt: {
                xs: '4rem',
                md: '4rem',
              },
              pb: {
                xs: '2rem',
              },
              px: {
                xs: '1rem',
              },
              width: {
                xs: '100%',
                md: '680px',
              },
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Paper
                sx={{ maxWidth: '350px', pt: '50px', pb: '50px', px: '50px' }}
              >
                <Stack direction={'column'} gap={'32px'}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      alt="TailorCare logo"
                      src={tcHorizontalLogo}
                      width={280}
                    />
                  </Box>
                  <SignInButton
                    provider={CognitoHostedUIIdentityProvider.Cognito}
                  />
                </Stack>
              </Paper>
            </Box>
            <BackdropLoader open={authIsLoading} />
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}
