import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@mui/material'

import { YesNoRadioGroupProps } from 'src/presentation'

export function YesNoRadioGroup({
  label,
  id,
  error,
  helperText,
  dataTestIdPreffix = '',
  ...props
}: YesNoRadioGroupProps) {
  return (
    <FormControl error={error}>
      <FormLabel
        id={`${id}-label`}
        sx={{
          fontWeight: 500,
          fontSize: '1rem',
          lineHeight: '25px',
          color: 'common.black',
          marginBottom: '2rem',
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup row id={id} {...props}>
        <FormControlLabel
          value={'yes'}
          control={<Radio />}
          label={'Yes'}
          data-testid={`${dataTestIdPreffix}_yes`}
        />
        <FormControlLabel
          value={'no'}
          control={<Radio />}
          label={'No'}
          data-testid={`${dataTestIdPreffix}_no`}
        />
      </RadioGroup>
      {error ? (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  )
}
