import { FC } from 'react'

import {
  MSKForm,
  MSKFormProvider,
  useLoadMSKProviderForm,
} from 'src/presentation'

export const PTAIFPage: FC = () => {
  const {
    loadedMSKFormData,
    loadMSKProviderFormIsError,
    loadMSKProviderFormIsSuccess,
  } = useLoadMSKProviderForm()
  if (loadMSKProviderFormIsError) {
    return <div>Error loading msk form.</div>
  }
  if (loadMSKProviderFormIsSuccess && loadedMSKFormData) {
    return (
      <MSKFormProvider loadedFormData={loadedMSKFormData}>
        <MSKForm />
      </MSKFormProvider>
    )
  }
  return null
}
