import { FC } from 'react'

import {
  MSKForm,
  useLoadMSKPatientForm,
  useTCId,
  LoadMSKPatientFormContainerProps,
  MSKFormProvider,
} from 'src/presentation'

export const LoadMSKPatientFormContainer: FC<
  LoadMSKPatientFormContainerProps
> = ({ tcId }) => {
  const {
    loadedMSKFormData,
    loadMSKPatientFormIsError,
    loadMSKPatientFormIsSuccess,
  } = useLoadMSKPatientForm({
    tcId,
  })

  if (loadMSKPatientFormIsError) {
    return <div>Invalid TC ID</div>
  }
  if (loadMSKPatientFormIsSuccess && loadedMSKFormData) {
    return (
      <MSKFormProvider loadedFormData={loadedMSKFormData}>
        <MSKForm />
      </MSKFormProvider>
    )
  }
  return null
}

export const PatientPTAIFPage: FC = () => {
  const tcId = useTCId()

  if (!tcId) {
    return (
      <div>
        <p>TC ID is not provided</p>
      </div>
    )
  }
  return <LoadMSKPatientFormContainer tcId={tcId} />
}
