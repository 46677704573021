import { FC, ChangeEvent, useState, useEffect, useCallback } from 'react'
import {
  Box,
  Typography,
  Stack,
  SelectChangeEvent,
  Button,
  Icon,
  TextField,
  InputAdornment,
  Link,
  // FormControlLabel,
  // Checkbox,
  // FormHelperText,
  Modal,
  Alert,
  AlertTitle,
  // FormControl,
  // FormLabel,
  // RadioGroup,
  // Radio,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation'
import InputMask from 'react-input-mask'
import { useMutation } from '@tanstack/react-query'

import {
  usePTAIFPatientAdapter,
  usePTAIFProviderAdapter,
  mapToProcessMSKFormArgs,
} from 'src/adapters'
import {
  SelectField,
  CheckboxesGroup,
  SliderField,
  YesNoRadioGroup,
  MSKFormPaginationProps,
  MSKFormStepThreeProps,
  ProcessMSKFormFn,
  MSKFormStepTwoProps,
  TCCopyToClipboard,
} from 'src/presentation'
import { useMSKFormContext } from 'src/infrastructure'
import {
  MSKFormBodyPart,
  MSKFormOption,
  MSKFormPreviousAction,
  MSKFormQuestion,
} from 'src/domain'
import {
  getStep2Question,
  getStep2Action,
  isAStep2ActionId,
  isAStep2QuestionId,
  getStep2CurrentAnswer,
} from 'src/utils'
import * as settings from 'src/config/settings'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
}

export const MSKFormPagination: FC<MSKFormPaginationProps> = ({
  onNext,
  onPrevious,
  showNext = true,
  showPrevious = true,
}) => {
  return (
    <Stack direction={'row'} justifyContent={'end'}>
      <Stack direction={'row'} columnGap={2}>
        {showPrevious ? (
          <Button
            data-testid={'msk_form_pagination_back'}
            variant={'outlined'}
            color={'secondary'}
            onClick={onPrevious}
          >
            BACK
          </Button>
        ) : null}
        {showNext ? (
          <Button
            data-testid={'msk_form_pagination_next'}
            variant={'contained'}
            color={'secondary'}
            endIcon={<Icon>arrow_forward</Icon>}
            onClick={onNext}
          >
            NEXT
          </Button>
        ) : null}
      </Stack>
    </Stack>
  )
}

export const PainLevelSliderFriendlyMarks: FC = () => {
  const { setHowSevereIsYourPain } = useMSKFormContext()
  return (
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Box
        sx={{
          width: { sm: '130px', xs: '70px' },
          display: 'flex',
          justifyContent: 'start',
        }}
      >
        <Stack direction={'column'}>
          <Icon
            sx={{ color: '#4CAF50', cursor: 'pointer' }}
            onClick={() => setHowSevereIsYourPain(0)}
          >
            sentiment_very_satisfied
          </Icon>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={'caption'}
            onClick={() => setHowSevereIsYourPain(0)}
          >
            No pain
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          width: { sm: '130px', xs: '90px' },
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Stack direction={'column'} alignItems={'center'}>
          <Icon
            sx={{ color: '#ED6C02', cursor: 'pointer' }}
            onClick={() => setHowSevereIsYourPain(5)}
          >
            sentiment_dissatisfied
          </Icon>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={'caption'}
            onClick={() => setHowSevereIsYourPain(5)}
          >
            Moderate Pain
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          width: { sm: '130px', xs: '70px' },
          display: 'flex',
          justifyContent: 'end',
          textAlign: 'end',
        }}
      >
        <Stack direction={'column'} alignItems={'end'}>
          <Icon
            sx={{ color: '#D32F2F', cursor: 'pointer' }}
            onClick={() => setHowSevereIsYourPain(10)}
          >
            mood_bad
          </Icon>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={'caption'}
            onClick={() => setHowSevereIsYourPain(10)}
          >
            Worst pain possible
          </Typography>
        </Stack>
      </Box>
    </Stack>
  )
}

export const AbilityLevelSliderFriendlyMarks: FC = () => {
  const { setHowMuchDoesThisLimitYourAbilities } = useMSKFormContext()
  return (
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Box
        sx={{
          width: '180px',
          display: 'flex',
          justifyContent: 'start',
        }}
      >
        <Stack direction={'column'}>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={'caption'}
            onClick={() => setHowMuchDoesThisLimitYourAbilities(0)}
          >
            Completely unable
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          width: '160px',
          display: 'flex',
          justifyContent: 'end',
          textAlign: 'end',
        }}
      >
        <Stack direction={'column'} alignItems={'end'}>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={'caption'}
            onClick={() => setHowMuchDoesThisLimitYourAbilities(10)}
          >
            Able to perform as normal
          </Typography>
        </Stack>
      </Box>
    </Stack>
  )
}

export const MSKFormStepOne = () => {
  const {
    setStep,
    page,
    setPage,
    selectedBotheredBodyPart,
    setSelectedBotheredBodyPart,
    selectedPreviouslyTriedActions,
    setSelectedPreviouslyTriedActions,
    selectedActionsOpenToTry,
    setSelectedActionsOpenToTry,
    howSevereIsYourPain,
    setHowSevereIsYourPain,
    howMuchDoesThisLimitYourAbilities,
    setHowMuchDoesThisLimitYourAbilities,
    setStep2StartQuestion,
    errors,
    setErrors,
    loadedFormData,
  } = useMSKFormContext()

  const bodyParts = loadedFormData.s1.botheredBodyParts
  const previouslyTriedActionsList = loadedFormData.s1.previouslyTriedActions
  const actionsOpenToTryList = loadedFormData.s1.actionsOpenToTry

  const handleChangeBodyPart = (event: SelectChangeEvent) => {
    setSelectedBotheredBodyPart(event.target.value)
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      delete newErrors.selectedBotheredBodyPart
      return newErrors
    })
  }

  const handleChangePreviouslyTried = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setSelectedPreviouslyTriedActions((prevState) => {
      if (event.target.checked && !prevState.includes(value)) {
        return [...prevState, value]
      }

      if (!event.target.checked && prevState.includes(value)) {
        return prevState.filter((item) => item !== value)
      }

      return prevState
    })
  }

  const handleChangeActionsOpenToTry = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setSelectedActionsOpenToTry((prevState) => {
      if (event.target.checked && !prevState.includes(value)) {
        return [...prevState, value]
      }

      if (!event.target.checked && prevState.includes(value)) {
        return prevState.filter((item) => item !== value)
      }

      return prevState
    })
  }

  const handleHowServereIsYourPainChange = (
    event: Event,
    value: number | number[]
  ) => {
    setHowSevereIsYourPain(value as number)
  }

  const handleHowMuchDoesThisLimitYourAbilitiesChange = (
    event: Event,
    value: number | number[]
  ) => {
    setHowMuchDoesThisLimitYourAbilities(value as number)
  }

  const handleNextPage = () => {
    if (selectedBotheredBodyPart) {
      const step2StartQuestion =
        loadedFormData.s2.start[selectedBotheredBodyPart]
      setStep2StartQuestion(step2StartQuestion)
    }
    if (page === 1) {
      if (isValidPage1()) {
        setPage(2)
      }
    }
    if (page === 2) {
      setPage(1)
      setStep(2)
    }
  }

  const handlePreviousPage = () => {
    setPage((currentPage) => currentPage - 1)
  }

  const isValidPage1 = () => {
    const errors: Record<string, string> = {}
    if (!selectedBotheredBodyPart) {
      errors.selectedBotheredBodyPart = 'Please select a body part'
    }
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const shouldShowPrevious = () => {
    return page === 2
  }

  return (
    <>
      {page === 1 ? (
        <>
          <Stack direction={'column'} rowGap={3}>
            <Typography
              variant={'h1'}
              color={'green.200'}
              sx={{ fontSize: '2rem', fontWeight: '400' }}
            >
              Welcome to TailorCare!
            </Typography>
            <Typography variant={'body1'}>
              TailorCare helps you navigate the complex healthcare system -
              getting you the right care, tailored to your specific needs, at
              the right time.
            </Typography>
            <Typography variant={'body1'}>
              When it comes to getting care for bones, joints, and muscles, many
              people have trouble understanding their diagnoses and how to
              choose the best treatment options for their unique needs.
              TailorCare takes away the frustration and confusion that can come
              with figuring out what your treatment options are and where to get
              care.
            </Typography>
          </Stack>
          <Stack direction={'column'} rowGap={3}>
            <Typography
              variant={'h2'}
              sx={{ fontSize: '1.5rem', color: 'green.400', fontWeight: '400' }}
            >
              Getting care for your joint, bone, and muscle pain just got a
              whole lot easier.
            </Typography>
            <Typography variant={'body1'}>
              Fill out this brief intake form to start your journey with
              TailorCare and find the treatment plan that&apos;s right for you.
            </Typography>
          </Stack>
          <Box sx={{ maxWidth: '383px' }}>
            <SelectField<MSKFormBodyPart>
              id={'body-part'}
              data-testid={'step_1_bothering_body_part_select'}
              label={'What part of the body is bothering you?'}
              name={'bodyPart'}
              value={selectedBotheredBodyPart}
              onChange={handleChangeBodyPart}
              menuItems={bodyParts}
              getMenuItemKey={(bodyPart) => bodyPart.id}
              getMenuItemValue={(bodyPart) => bodyPart.id}
              getMenuItemLabel={(bodyPart) => bodyPart.display}
              helperText={
                errors.selectedBotheredBodyPart
                  ? errors.selectedBotheredBodyPart
                  : undefined
              }
              error={!!errors.selectedBotheredBodyPart}
            />
          </Box>
          <Box sx={{ maxWidth: '600px' }}>
            <Stack direction={'column'} rowGap={4}>
              <CheckboxesGroup<MSKFormPreviousAction>
                dataTestIdPreffix={'step_1_previously_tried_checkbox'}
                label={'What have you previously tried?'}
                name={'previouslyTriedActions'}
                choicesList={previouslyTriedActionsList}
                selectedChoices={selectedPreviouslyTriedActions}
                handleChange={handleChangePreviouslyTried}
                getChoiceKey={(choice) => choice.id}
                getChoiceLabel={(choice) => choice.display}
                getChoiceValue={(choice) => choice.id}
              />
              <CheckboxesGroup<MSKFormPreviousAction>
                dataTestIdPreffix={'step_1_actions_open_to_try_checkbox'}
                label={'What options are you open to trying?'}
                name={'actionsOpenToTry'}
                choicesList={actionsOpenToTryList}
                selectedChoices={selectedActionsOpenToTry}
                handleChange={handleChangeActionsOpenToTry}
                getChoiceKey={(choice) => choice.id}
                getChoiceLabel={(choice) => choice.display}
                getChoiceValue={(choice) => choice.id}
              />
            </Stack>
          </Box>
        </>
      ) : null}
      {page === 2 ? (
        <Box>
          <Stack direction={'column'} rowGap={10}>
            <SliderField
              data-testid={'step_1_how_severe_is_your_pain_slider'}
              label={'What is your current pain level?'}
              value={howSevereIsYourPain}
              onChange={handleHowServereIsYourPainChange}
              friendlyMarks={<PainLevelSliderFriendlyMarks />}
            />
            <SliderField
              data-testid={
                'step_1_how_much_does_this_limit_your_abilities_slider'
              }
              label={
                'How would you rate your ability to do your normal daily activities? (such as brushing your teeth, walking your dog, etc.)'
              }
              value={howMuchDoesThisLimitYourAbilities}
              onChange={handleHowMuchDoesThisLimitYourAbilitiesChange}
              friendlyMarks={<AbilityLevelSliderFriendlyMarks />}
            />
          </Stack>
        </Box>
      ) : null}
      <MSKFormPagination
        onNext={handleNextPage}
        onPrevious={handlePreviousPage}
        showPrevious={shouldShowPrevious()}
      />
    </>
  )
}

export const useMSKProviderForm = () => {
  const PTAIFProviderAdapter = usePTAIFProviderAdapter()

  const processMSKFormFn: MSKFormStepThreeProps['processMSKFormFn'] = (
    context,
    hardstop = false
  ) => {
    return PTAIFProviderAdapter.processMSKProviderForm(
      mapToProcessMSKFormArgs(context, hardstop)
    )
  }

  return {
    processMSKFormFn,
  }
}

export const useMSKPatientForm = () => {
  const { loadedFormData } = useMSKFormContext()
  const PTAIFPatientAdapter = usePTAIFPatientAdapter()

  const processMSKFormFn: ProcessMSKFormFn = (context, hardstop = false) => {
    return PTAIFPatientAdapter.processMSKPatientForm(
      loadedFormData.token as string,
      mapToProcessMSKFormArgs(context, hardstop)
    )
  }

  return {
    processMSKFormFn,
  }
}

export const MSKFormStepTwo: FC<MSKFormStepTwoProps> = ({
  processMSKFormFn,
}) => {
  const mskFormContext = useMSKFormContext()
  const {
    page,
    setPage,
    setStep,
    selectedBotheredBodyPart,
    loadedFormData,
    step2StartQuestion,
    step2NextQuestion,
    setStep2NextQuestion,
    step2Action,
    setStep2Action,
    step2NextAction,
    setStep2ActionCode,
    setStep2NextAction,
    step2Answers,
    setStep2Answers,
    step2QuestionHistory,
    setStep2QuestionHistory,
  } = mskFormContext
  const [currentQuestion, setCurrentQuestion] =
    useState<MSKFormQuestion | null>(
      getStep2Question(step2StartQuestion, loadedFormData.s2.questions)
    )
  const [currenAnswer, setCurrentAnswer] = useState<string>(
    getStep2CurrentAnswer(
      step2Answers,
      currentQuestion ? currentQuestion.id : ''
    )
  )
  const [validated, setValidated] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
  const [warningConfirm, setWarningConfirm] = useState<boolean>(false)

  const handleChange =
    (currentQuestion: MSKFormQuestion) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      const answer = value === 'yes' ? 'yes' : 'no'

      setCurrentAnswer(answer)

      setStep2Answers((prevAnswers) => {
        return {
          ...prevAnswers,
          [currentQuestion.id]: {
            id: currentQuestion.id,
            answer,
          },
        }
      })

      setStep2QuestionHistory((prevHistory) => {
        const currentQuestionIdx = prevHistory.findIndex(
          (questionId) => questionId === currentQuestion.id
        )
        if (currentQuestionIdx === -1) {
          return [...prevHistory, currentQuestion.id]
        }
        if (currentQuestionIdx > 0) {
          return prevHistory.slice(0, currentQuestionIdx + 1)
        }
        return [prevHistory[currentQuestionIdx]]
      })

      setValidated(false)
    }

  const handleNextPage = () => {
    setValidated(true)
    if (step2Action) {
      const action = getStep2Action(step2Action, loadedFormData.s2.actions)
      if (action.hardstop && showWarningModal) {
        processMSKFormFn(mskFormContext, true)
        setShowWarningModal(false)
        setWarningConfirm(true)
      }
      setPage((currentPage) => currentPage + 1)
    }
    if (currenAnswer) {
      if (step2NextAction) {
        setStep2Action(step2NextAction)
        setCurrentQuestion(null)
        setStep2NextAction('')
        setPage((currentPage) => currentPage + 1)
        const action = getStep2Action(
          step2NextAction,
          loadedFormData.s2.actions
        )
        setStep2ActionCode(action.action)
        if (action.hardstop && !showWarningModal) {
          setShowWarningModal(true)
          setWarningConfirm(false)
        } else {
          setPage(1)
          setStep(3)
        }
      } else {
        setPage((currentPage) => currentPage + 1)
        setCurrentQuestion(
          getStep2Question(step2NextQuestion, loadedFormData.s2.questions)
        )
        setStep2NextQuestion('')
        setValidated(false)
      }
      setCurrentAnswer('')
    }
  }

  const handlePreviousPage = () => {
    if (page == 1) {
      setPage(2)
      setStep(1)
    } else {
      const lastQuestionId =
        step2QuestionHistory[step2QuestionHistory.length - 1]
      if (
        step2Action &&
        getStep2Action(step2Action, loadedFormData.s2.actions).hardstop
      ) {
        setShowWarningModal(false)
        setWarningConfirm(false)
        setStep2Action('')
        setStep2ActionCode('')
        setCurrentAnswer('')
        setStep2Answers((prevAnswers) => {
          const newAnswers = { ...prevAnswers }
          delete newAnswers[lastQuestionId]
          return newAnswers
        })
        setCurrentQuestion(
          getStep2Question(lastQuestionId, loadedFormData.s2.questions)
        )
        setStep2QuestionHistory((prevHistory) => {
          const newHistory = [...prevHistory]
          newHistory.pop()
          return newHistory
        })
      }
      if (currentQuestion) {
        let setQuestionIdTo = lastQuestionId

        const currentQuestionIdx = step2QuestionHistory.findIndex(
          (questionId) => questionId === currentQuestion.id
        )

        if (currentQuestionIdx > 0) {
          setQuestionIdTo = step2QuestionHistory[currentQuestionIdx - 1]
        }

        setCurrentQuestion(
          getStep2Question(setQuestionIdTo, loadedFormData.s2.questions)
        )
        setCurrentAnswer(
          step2Answers[setQuestionIdTo]
            ? step2Answers[setQuestionIdTo].answer
            : ''
        )
      }
      setValidated(false)
      setPage((currentPage) => currentPage - 1)
    }
  }

  const showHardStop = useCallback(() => {
    if (step2Action) {
      return (
        getStep2Action(step2Action, loadedFormData.s2.actions).hardstop &&
        warningConfirm
      )
    }
    return false
  }, [loadedFormData.s2.actions, step2Action, warningConfirm])

  useEffect(() => {
    if (currentQuestion && currenAnswer) {
      const answerKey = currenAnswer === 'yes' ? 'y' : 'n'

      const nextId =
        currentQuestion.answers[answerKey].nextStep[selectedBotheredBodyPart]

      if (isAStep2QuestionId(nextId)) {
        setStep2NextAction('')
        setStep2NextQuestion(nextId)
      }

      if (isAStep2ActionId(nextId)) {
        setStep2NextAction(nextId)
        setStep2NextQuestion('')
      }
    } else if (currentQuestion && !currenAnswer) {
      setCurrentAnswer(
        step2Answers[currentQuestion.id]
          ? step2Answers[currentQuestion.id].answer
          : ''
      )
    }
  }, [
    currenAnswer,
    currentQuestion,
    selectedBotheredBodyPart,
    setStep2Action,
    setStep2ActionCode,
    setStep2NextAction,
    setStep2NextQuestion,
    step2Answers,
  ])

  useEffect(() => {
    if (step2QuestionHistory.length !== Object.keys(step2Answers).length) {
      setStep2Answers((prevAnswers) => {
        const newAnswers = { ...prevAnswers }
        Object.keys(newAnswers).forEach((answerId) => {
          if (!step2QuestionHistory.includes(answerId)) {
            delete newAnswers[answerId]
          }
        })
        return newAnswers
      })
    }
  }, [step2QuestionHistory, step2Answers, setStep2Answers])

  return (
    <>
      {showHardStop() ? (
        <Alert severity="error">
          <AlertTitle>Serious Symptom Warning</AlertTitle>
          Saddle numbness and loss of bowel or bladder function are symptoms
          often related to an emergent condition. Please go to your nearest
          emergency room as soon as possible. You can also call TailorCare
          directly at{' '}
          <Link href={`tel:${settings.TC_PHONE}`} underline={'none'}>
            <Typography color={'secondary'} component={'span'}>
              {settings.TC_PHONE_DISPLAY}
            </Typography>
          </Link>
        </Alert>
      ) : null}
      {currentQuestion ? (
        <>
          <YesNoRadioGroup
            dataTestIdPreffix={'step2_question_radio'}
            label={currentQuestion.q}
            id={currentQuestion.id}
            onChange={handleChange(currentQuestion)}
            error={validated && !currenAnswer}
            helperText={'Please select an answer'}
            value={currenAnswer}
          />
          <MSKFormPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
          />
        </>
      ) : null}
      <Modal
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Serious Symptom Warning
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your answer indicates a serious concern. Are you sure you are
            experiencing saddle numbness and loss of bowel or bladder function?
          </Typography>
          <Stack direction={'row'} justifyContent={'end'}>
            <Button
              data-testid={'msk_form_red_flag_warning_confirm_no'}
              variant={'text'}
              color={'secondary'}
              onClick={handlePreviousPage}
            >
              No
            </Button>
            <Button
              data-testid={'msk_form_red_flag_warning_confirm_yes'}
              variant={'text'}
              color={'secondary'}
              onClick={handleNextPage}
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}

export const MSKFormStepTwoPatient = () => {
  const { processMSKFormFn } = useMSKPatientForm()

  return <MSKFormStepTwo processMSKFormFn={processMSKFormFn} />
}

export const MSKFormStepTwoProvider = () => {
  const { processMSKFormFn } = useMSKProviderForm()

  return <MSKFormStepTwo processMSKFormFn={processMSKFormFn} />
}

export const MSKFormStepThree: FC<MSKFormStepThreeProps> = ({
  processMSKFormFn,
}) => {
  const mskFormContext = useMSKFormContext()
  const {
    page,
    setPage,
    setStep,
    errors,
    setErrors,
    step2QuestionHistory,
    setStep2StartQuestion,
    setStep2Action,
    setStep2ActionCode,
    step3FirstName,
    setStep3FirstName,
    step3LastName,
    setStep3LastName,
    step3Medicare,
    setStep3Medicare,
    step3DateOfBirth,
    setStep3DateOfBirth,
    step3HeightFt,
    setStep3HeightFt,
    step3HeightIn,
    setStep3HeightIn,
    step3Weight,
    step3HowDoYouPreferToBeContacted,
    setStep3HowDoYouPreferToBeContacted,
    step3ContactConsent,
    setStep3ContactConsent,
    step3Phone,
    setStep3Phone,
    setStep3Weight,
    setMskProcessResponse,
  } = mskFormContext

  const { mutate: processMSKForm, isError: processMSKFormIsError } =
    useMutation(processMSKFormFn, {
      onSuccess: (data) => {
        setMskProcessResponse(data)
        setStep(4)
        setPage(1)
      },
    })

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setStep3FirstName(value)
    if (value) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors }
        delete newErrors.step3FirstName
        return newErrors
      })
    }
  }

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setStep3LastName(value)
    if (value) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors }
        delete newErrors.step3LastName
        return newErrors
      })
    }
  }

  const handleChangeMedicare = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setStep3Medicare(value)
  }

  const handleDateOfBirthChange = (date: Date | null) => {
    setStep3DateOfBirth(date)
  }

  const handleDateOfBirthError = (reason: DateValidationError) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      if (reason) {
        newErrors.step3DateOfBirth = reason
      } else {
        delete newErrors.step3DateOfBirth
      }
      return newErrors
    })
  }

  const handleDateOfBirthAccept = (date: Date | null) => {
    if (date) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors }
        delete newErrors.step3DateOfBirth
        return newErrors
      })
    }
  }

  const handleHeightFtChange = (event: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[1-7]$/
    const value = event.target.value
    if (value === '' || regex.test(value)) {
      setStep3HeightFt(value)
      if (value) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors }
          delete newErrors.step3HeightFt
          return newErrors
        })
      }
    }
  }

  const handleHeightInChange = (event: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9]{1,2}$/
    const value = event.target.value
    if (value === '' || regex.test(value)) {
      setStep3HeightIn(value)
      const numValue = parseInt(value, 10)
      if (numValue >= 0 && numValue <= 11) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors }
          delete newErrors.step3HeightIn
          return newErrors
        })
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors }
          newErrors.step3HeightIn = 'Max value is 11'
          return newErrors
        })
      }
    }
  }

  const handleWeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9]{1,3}(\.)?(\d{1,3})?$/
    const value = event.target.value
    if (value === '' || regex.test(value)) {
      setStep3Weight(value)
      if (value) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors }
          delete newErrors.step3Weight
          return newErrors
        })
      }
    }
  }

  const handleHowDoYouPreferToBeContacted = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setStep3HowDoYouPreferToBeContacted(value)
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      delete newErrors.step3HowDoYouPreferToBeContacted
      delete newErrors.step3ContactConsent
      return newErrors
    })
  }
  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const filteredValue = value.replace(/[^0-9]/g, '')
    if (value === '' || filteredValue) {
      setStep3Phone(filteredValue)
      if (filteredValue.length === 10) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors }
          delete newErrors.step3Phone
          return newErrors
        })
      }
    }
  }

  const handleContactConsentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStep3ContactConsent(event.target.checked)
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      delete newErrors.step3ContactConsent
      return newErrors
    })
  }

  const isValidPage1 = () => {
    const newErrors: Record<string, string> = {}
    if (!step3FirstName) {
      newErrors.step3FirstName = 'Please enter your first name'
    }
    if (!step3LastName) {
      newErrors.step3LastName = 'Please enter your last name'
    }
    if (!step3DateOfBirth) {
      newErrors.step3DateOfBirth = 'Please enter your date of birth'
    }
    if (!step3HeightFt) {
      newErrors.step3HeightFt = 'Please enter your height'
    }
    if (!step3Weight) {
      newErrors.step3Weight = 'Please enter your weight'
    }
    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }))
    return Object.keys({ ...errors, ...newErrors }).length === 0
  }

  const isValidPage2 = () => {
    const newErrors: Record<string, string> = {}
    // if (!step3HowDoYouPreferToBeContacted.length) {
    //   newErrors.step3HowDoYouPreferToBeContacted = 'Please select an option'
    // }
    if (!step3Phone) {
      newErrors.step3Phone = 'Please enter your phone number'
    } else if (step3Phone.length !== 10) {
      newErrors.step3Phone = 'Phone number must be 10 digits'
    }
    // if (step3HowDoYouPreferToBeContacted === 'text' && !step3ContactConsent) {
    //   newErrors.step3ContactConsent = 'Please check the box'
    // }
    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }))
    return Object.keys({ ...errors, ...newErrors }).length === 0
  }

  const handleNextPage = () => {
    if (page == 1) {
      if (isValidPage1()) {
        setPage((currentPage) => currentPage + 1)
      }
    } else if (page == 2) {
      if (isValidPage2()) {
        processMSKForm(mskFormContext)
      }
    }
  }

  const handlePreviousPage = () => {
    if (page == 1) {
      setPage(step2QuestionHistory.length)
      setStep2StartQuestion(
        step2QuestionHistory[step2QuestionHistory.length - 1]
      )
      setStep(2)
      setStep2Action('')
      setStep2ActionCode('')
    } else {
      setPage((currentPage) => currentPage - 1)
    }
  }

  if (processMSKFormIsError) {
    return (
      <Alert severity="error">
        <AlertTitle>Error processing the form answers</AlertTitle>
        Please reload the page and try to fill the form again. You can also call
        TailorCare directly at{' '}
        <Link href={`tel:${settings.TC_PHONE}`} underline={'none'}>
          <Typography color={'secondary'} component={'span'}>
            {settings.TC_PHONE_DISPLAY}
          </Typography>
        </Link>
      </Alert>
    )
  }

  return (
    <>
      {page === 1 ? (
        <Box sx={{ rowGap: '2rem', display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Stack gap={'2rem'}>
              <TextField
                data-testid={'step_3_first_name_text_field'}
                name={'step3FirstName'}
                label={'First Name'}
                value={step3FirstName}
                onChange={handleFirstNameChange}
                error={!!errors.step3FirstName}
                helperText={errors.step3FirstName ? errors.step3FirstName : ''}
              />
              <TextField
                data-testid={'step_3_last_name_text_field'}
                name={'step3LastName'}
                label={'Last Name'}
                value={step3LastName}
                onChange={handleLastNameChange}
                error={!!errors.step3LastName}
                helperText={errors.step3LastName ? errors.step3LastName : ''}
              />
            </Stack>
          </Box>
          <Box sx={{ maxWidth: { xs: '280px', md: '332px' } }}>
            <Stack gap={'2rem'}>
              <TextField
                data-testid={'step_3_medicare_text_field'}
                name={'step3Medicare'}
                label={'Medicare Advantage Member ID'}
                value={step3Medicare}
                onChange={handleChangeMedicare}
                error={!!errors.step3Medicare}
                helperText={errors.step3Medicare ? errors.step3Medicare : ''}
                fullWidth
              />
              <Box sx={{ maxWidth: '160px' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="DOB"
                    views={['year', 'month', 'day']}
                    mask={'__/__/____'}
                    inputFormat={'MM/dd/yyyy'}
                    value={step3DateOfBirth}
                    onChange={handleDateOfBirthChange}
                    renderInput={(params) => (
                      <TextField
                        data-testid={'step_3_date_of_birth_date_picker'}
                        {...params}
                        error={!!errors.step3DateOfBirth}
                        helperText={
                          errors.step3DateOfBirth ? errors.step3DateOfBirth : ''
                        }
                      />
                    )}
                    onError={handleDateOfBirthError}
                    onAccept={handleDateOfBirthAccept}
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ maxWidth: '250px' }}>
                <Stack direction={'row'} gap={'1rem'}>
                  <TextField
                    data-testid={'step_3_height_ft_text_field'}
                    name={'step3HeightFt'}
                    label={'Height'}
                    value={step3HeightFt}
                    onChange={handleHeightFtChange}
                    error={!!errors.step3HeightFt}
                    helperText={
                      errors.step3HeightFt ? errors.step3HeightFt : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={'end'}>ft</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    data-testid={'step_3_height_in_text_field'}
                    name={'step3HeightIn'}
                    label={'Height'}
                    value={step3HeightIn}
                    onChange={handleHeightInChange}
                    error={!!errors.step3HeightIn}
                    helperText={
                      errors.step3HeightIn ? errors.step3HeightIn : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={'end'}>in</InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Box>
              <Box sx={{ maxWidth: '120px' }}>
                <TextField
                  data-testid={'step_3_weight_text_field'}
                  name={'step3Weight'}
                  label={'Weight'}
                  value={step3Weight}
                  onChange={handleWeightChange}
                  error={!!errors.step3Weight}
                  helperText={errors.step3Weight ? errors.step3Weight : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>lbs</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </Box>
      ) : null}
      {page === 2 ? (
        <>
          {/* <FormControl error={!!errors.step3HowDoYouPreferToBeContacted}>
            <FormLabel
              id={'how-do-you-prefer-to-be-contacted-label'}
              sx={{
                fontWeight: 500,
                fontSize: '1rem',
                lineHeight: '25px',
                color: 'common.black',
                marginBottom: '2rem',
              }}
            >
              {'How do you prefer TailorCare communicates with you?'}
            </FormLabel>
            <RadioGroup
              id={'how-do-you-prefer-to-be-contacted'}
              value={step3HowDoYouPreferToBeContacted}
              onChange={handleHowDoYouPreferToBeContacted}
            >
              <FormControlLabel
                value={'phone'}
                control={<Radio />}
                label={'Phone'}
                data-testid={
                  'step_3_how_do_you_prefer_to_be_contacted_radio_phone'
                }
              />
              <FormControlLabel
                value={'text'}
                control={<Radio />}
                label={'Text'}
                data-testid={
                  'step_3_how_do_you_prefer_to_be_contacted_radio_text'
                }
              />
            </RadioGroup>
            {errors.step3HowDoYouPreferToBeContacted ? (
              <FormHelperText error={!!errors.step3HowDoYouPreferToBeContacted}>
                {errors.step3HowDoYouPreferToBeContacted}
              </FormHelperText>
            ) : null}
          </FormControl> */}
          <InputMask
            mask="(999) 999-9999"
            value={step3Phone}
            onChange={handlePhoneChange}
          >
            <TextField
              data-testid={'step_3_phone_text_field'}
              name={'step3Phone'}
              label={'Phone Number'}
              error={!!errors.step3Phone}
              helperText={errors.step3Phone ? errors.step3Phone : ''}
            />
          </InputMask>
          {/* <Box>
            <FormControlLabel
              componentsProps={{
                typography: {
                  color: `${
                    errors.step3ContactConsent ? 'error.main' : 'common.black'
                  }`,
                  variant: 'body2',
                },
              }}
              control={
                <Checkbox
                  data-testid={'step_3_contact_consent_checkbox'}
                  color={'primary'}
                  name={'step3ContactConsent'}
                  onChange={handleContactConsentChange}
                  checked={step3ContactConsent}
                />
              }
              label={
                'Do you consent to receive text messages from TailorCare?.'
              }
            />
            {errors.step3ContactConsent ? (
              <FormHelperText
                variant={'outlined'}
                error={!!errors.step3ContactConsent}
              >
                {errors.step3ContactConsent ? errors.step3ContactConsent : null}
              </FormHelperText>
            ) : null}
          </Box> */}
        </>
      ) : null}
      <MSKFormPagination
        onNext={handleNextPage}
        onPrevious={handlePreviousPage}
      />
    </>
  )
}

export const MSKFormStepThreePatient = () => {
  const { processMSKFormFn } = useMSKPatientForm()

  return <MSKFormStepThree processMSKFormFn={processMSKFormFn} />
}

export const MSKFormStepThreeProvider = () => {
  const { processMSKFormFn } = useMSKProviderForm()

  return <MSKFormStepThree processMSKFormFn={processMSKFormFn} />
}

export const MSKFormStepFourThankYou = () => {
  const { step3FirstName } = useMSKFormContext()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
      <Typography
        variant={'h1'}
        color={'green.200'}
        sx={{ fontSize: '2rem', fontWeight: '400' }}
      >
        Thanks {step3FirstName}, Your Intake is Complete!
      </Typography>
      <Typography
        variant={'h2'}
        sx={{ fontSize: '1.5rem', color: 'green.400', fontWeight: '400' }}
      >
        Thank you for completing your intake form.
      </Typography>
      <Typography variant={'body1'}>
        If you would like to review your treatment recommendations now, please
        call TailorCare at{' '}
        <Link href={`tel:${settings.TC_PHONE}`} underline={'none'}>
          <Typography color={'black'} component={'span'} fontWeight={500}>
            {settings.TC_PHONE_DISPLAY}
          </Typography>
        </Link>{' '}
        and let the team know that you just completed your intake form.
        Otherwise, someone from your care team will give you a call shortly.
      </Typography>
      <Typography>
        Thank you for letting us be part of your care journey.
      </Typography>
    </Box>
  )
}

export const MSKFormStepFour = () => {
  const [tcRecommendationsCopied, setTcRecommendationsCopied] = useState(false)
  const {
    loadedFormData,
    mskProcessResponse,
    selectedPreviouslyTriedActions,
    selectedActionsOpenToTry,
    step3HeightFt,
    step3HeightIn,
    step3Weight,
    step2QuestionHistory,
    step2Answers,
  } = useMSKFormContext()

  const Section1Item = ({ label, value }: { label: string; value: string }) => (
    <Box sx={{ display: 'flex', columnGap: '8px' }}>
      <Typography>{label}:</Typography>
      <Typography sx={{ fontWeight: 'bold' }}>{value}</Typography>
    </Box>
  )

  const Section2Item = ({ label, value }: { label: string; value: string }) => (
    <Box sx={{ display: 'flex', justifyContent: 'start', columnGap: '25px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          flex: '0 0 140px',
        }}
      >
        <Typography color={'rgba(0, 0, 0, 0.6)'}>{label}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          flex: '1 1 140px',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{value}</Typography>
      </Box>
    </Box>
  )

  const getSelectedBodyRegion = () => {
    const bodyRegionId = mskProcessResponse.questionPath.botheredBodyPart
    const bodyRegion = loadedFormData.s1.botheredBodyParts.find(
      (bodyPart) => bodyPart.id === bodyRegionId
    )?.display
    return bodyRegion || ''
  }

  const getPreviouslyTriedActions = () => {
    const previouslyTriedActions: MSKFormOption[] =
      loadedFormData.s1.previouslyTriedActions.filter((action) =>
        selectedPreviouslyTriedActions.includes(action.id)
      )
    return previouslyTriedActions.map((action) => action.display).join(', ')
  }

  const getOpenToTryActions = () => {
    const actionsOpenToTry: MSKFormOption[] =
      loadedFormData.s1.actionsOpenToTry.filter((action) =>
        selectedActionsOpenToTry.includes(action.id)
      )
    return actionsOpenToTry.map((action) => action.display).join(', ')
  }

  const getQuestion = (questionId: string) => {
    return loadedFormData.s2.questions[questionId].q
  }

  const getCopyToClipboardText = () => {
    let finalValue = `
    Body Region: ${getSelectedBodyRegion()},
    Recommended Care Pathway: ${mskProcessResponse.pathway},
    Recommended Type: ${mskProcessResponse.track},
    Care Pathway ID: ${mskProcessResponse.pathwayId},

    Previously Tried Actions: ${getPreviouslyTriedActions()},
    Open to Try Actions: ${getOpenToTryActions()},
    Height: ${step3HeightFt}'${step3HeightIn}",
    Weight: ${step3Weight} Lbs,

    `
    step2QuestionHistory.forEach((questionId) => {
      const question = getQuestion(questionId)
      const answer = step2Answers[questionId].answer

      finalValue += `
      ${question}: ${answer}
      `
    })
    return finalValue
  }

  const handleTcRecommendationsCopyClick = () =>
    setTcRecommendationsCopied(true)
  const handleTcRecommendationsCopiedTooltipClose = () =>
    setTcRecommendationsCopied(false)
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      <Box sx={{ display: 'flex', columnGap: '16px' }}>
        <Typography
          variant={'h5'}
          sx={{
            fontSize: '24px',
            color: '#40787B',
          }}
        >
          Care Pathway Information
        </Typography>
        <TCCopyToClipboard
          value={getCopyToClipboardText()}
          tooltipOpen={tcRecommendationsCopied}
          onCopy={handleTcRecommendationsCopyClick}
          onTooltipClose={handleTcRecommendationsCopiedTooltipClose}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '4rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Section1Item label={'Body Region'} value={getSelectedBodyRegion()} />
          <Section1Item
            label={'Recommended Care Pathway'}
            value={mskProcessResponse.pathway}
          />
          <Section1Item
            label={'Recommended Type'}
            value={mskProcessResponse.track}
          />
          <Section1Item
            label={'Care Pathway ID'}
            value={mskProcessResponse.pathwayId}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '25px',
          }}
        >
          <Section2Item
            label={'Previously Tried'}
            value={getPreviouslyTriedActions()}
          />

          <Section2Item label={'Open to Tryng'} value={getOpenToTryActions()} />
          <Section2Item label={'BMI'} value={`${mskProcessResponse.bmi}`} />
          <Section2Item
            label={'Height'}
            value={`${step3HeightFt}' ${step3HeightIn}"`}
          />
          <Section2Item label={'Weight'} value={`${step3Weight}' Lbs`} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
            width: {
              xs: '100%',
              md: '75%',
            },
          }}
        >
          <Typography variant="h6">
            PT Assessment Questions & Answers
          </Typography>
          {step2QuestionHistory.map((questionId, index) => (
            <Box key={questionId}>
              <Box>
                <Typography color={'rgba(0, 0, 0, 0.6)'}>{`${
                  index + 1
                }. ${getQuestion(questionId)}`}</Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
                >
                  {step2Answers[questionId].answer}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export const MSKForm: FC = () => {
  const { step, formType } = useMSKFormContext()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: { xs: '24px', md: '40px' },
        justifyContent: { xs: 'space-between', md: 'start' },
        minHeight: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '2rem',
          minHeight: { xs: '80%', sm: '50%' },
          justifyContent: { xs: 'space-between', md: 'start' },
          boxSizing: 'border-box',
        }}
      >
        {step === 1 ? <MSKFormStepOne /> : null}
        {step === 2 ? (
          formType === 'patient' ? (
            <MSKFormStepTwoPatient />
          ) : (
            <MSKFormStepTwoProvider />
          )
        ) : null}
        {step === 3 ? (
          formType === 'patient' ? (
            <MSKFormStepThreePatient />
          ) : (
            <MSKFormStepThreeProvider />
          )
        ) : null}
        {step === 4 ? <MSKFormStepFour /> : null}
      </Box>

      <Stack
        direction={'column'}
        gap={'1rem'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography textAlign={'center'}>
          If you have any questions or would like to complete this form on the
          phone with a member of your care team, give us a call at{' '}
        </Typography>{' '}
        <Link href={`tel:${settings.TC_PHONE}`} underline={'none'}>
          <Typography color={'secondary'}>
            {settings.TC_PHONE_DISPLAY}
          </Typography>
        </Link>
      </Stack>
    </Box>
  )
}
