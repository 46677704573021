import {
  Box,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  FormHelperText,
} from '@mui/material'

import { CheckboxesGroupProps } from 'src/presentation'

export function CheckboxesGroup<TChoice>({
  label,
  name,
  choicesList,
  selectedChoices,
  handleChange,
  getChoiceKey,
  getChoiceLabel,
  getChoiceValue,
  error,
  helperText,
  maxRows = 6,
  dataTestIdPreffix = '',
}: CheckboxesGroupProps<TChoice>) {
  const getColumns = () => {
    const columnsQty =
      choicesList.length > maxRows ? Math.ceil(choicesList.length / maxRows) : 1

    const columns = []

    for (let i = 0; i < columnsQty; i++) {
      columns.push(choicesList.slice(i * maxRows, (i + 1) * maxRows))
    }

    return columns
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel
          component="legend"
          sx={{
            color: `${error ? 'error.main' : 'common.black'}`,
            fontWeight: '500',
          }}
        >
          {label}
        </FormLabel>
        <Box sx={{ m: '1rem 0 0 1rem' }}>
          <Stack display={'flex'} direction={'row'}>
            {getColumns().map((choicesColumn, index) => (
              <FormGroup key={index}>
                {choicesColumn.map((choice) => (
                  <FormControlLabel
                    key={getChoiceKey(choice)}
                    control={
                      <Checkbox
                        checked={selectedChoices.includes(
                          getChoiceValue(choice)
                        )}
                        onChange={handleChange}
                        name={name}
                        value={getChoiceValue(choice)}
                        sx={{ paddingTop: '0' }}
                        data-testid={`${dataTestIdPreffix}_${getChoiceKey(
                          choice
                        )}`}
                      />
                    }
                    label={getChoiceLabel(choice)}
                    sx={{
                      minWidth: '190px',
                      alignItems: 'flex-start',
                    }}
                  />
                ))}
              </FormGroup>
            ))}
          </Stack>
        </Box>
        {helperText ? (
          <FormHelperText variant={'outlined'} error={error}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </Box>
  )
}
