import { Typography, Stack, Slider } from '@mui/material'
import { FC } from 'react'

import { SliderFieldProps } from 'src/presentation'

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
    label: '10',
  },
]
export const SliderField: FC<SliderFieldProps> = ({
  value,
  onChange,
  label,
  friendlyMarks,
  ...props
}) => {
  return (
    <Stack direction={'column'} rowGap={2}>
      <Typography variant={'body1'} fontWeight={'500'}>
        {label}
      </Typography>
      <Stack>
        <Slider
          track={false}
          step={1}
          min={0}
          max={10}
          valueLabelDisplay="auto"
          marks={marks}
          value={value}
          onChange={onChange}
          {...props}
        />
        {friendlyMarks ? friendlyMarks : null}
      </Stack>
    </Stack>
  )
}
