import { useContext } from 'react'

import { UseMSKFormContext, MSKFormContext } from 'src/infrastructure'

export const useMSKFormContext: UseMSKFormContext = () => {
  const context = useContext(MSKFormContext)
  if (!context) {
    throw new Error(
      'useMSKFormContext must be used within a MSKFormContextProvider'
    )
  }
  return context
}
