import axios from 'axios'
import { useMemo, useCallback } from 'react'

import {
  LoadMSKPatientFormServicePayload,
  LoadMSKPatientFormServiceResponse,
  LoadMSKProviderFormServiceResponse,
  ProcessMSKFormServicePayload,
  ProcessMSKFormServiceResponse,
  useClientWithAuth,
} from 'src/infrastructure'
import * as settings from 'src/config/settings'

export const useLoadMSKPatientFormSevice = () => {
  const client = useMemo(() => {
    const axiosClient = axios.create({
      baseURL: settings.MSK_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': settings.MSK_TC_ID_VALIDATION_API_KEY,
        Authorization: `Bearer ${settings.MSK_TC_ID_VALIDATION_BEARER_TOKEN}`,
      },
    })
    return axiosClient
  }, [])

  const loadMSKPatientForm = useCallback(
    (payload: LoadMSKPatientFormServicePayload) => {
      return client.post<LoadMSKPatientFormServiceResponse>(
        '/ptaif-patient/load',
        payload
      )
    },
    [client]
  )

  return {
    loadMSKPatientForm,
  }
}

export const useLoadMSKProviderFormSevice = () => {
  const { clientWithAuth, idToken } = useClientWithAuth()

  const loadMSKProviderForm = useCallback(() => {
    return clientWithAuth.get<LoadMSKProviderFormServiceResponse>(
      settings.MSK_API_BASE_URL + '/ptaif/load',
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': settings.MSK_TC_ID_VALIDATION_API_KEY,
          Authorization: `Bearer ${idToken}`,
        },
      }
    )
  }, [clientWithAuth, idToken])

  return {
    loadMSKProviderForm,
  }
}

export const useProcessMSKPatientFormService = () => {
  const processMSKPatientForm = useCallback(
    (authToken: string, payload: ProcessMSKFormServicePayload) => {
      return axios.post<ProcessMSKFormServiceResponse>(
        settings.MSK_API_BASE_URL + '/ptaif-patient/process',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': settings.MSK_TC_ID_VALIDATION_API_KEY,
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
    },
    []
  )

  return {
    processMSKPatientForm,
  }
}

export const useProcessMSKProviderFormService = () => {
  const { clientWithAuth, idToken } = useClientWithAuth()

  const processMSKProviderForm = useCallback(
    (payload: ProcessMSKFormServicePayload) => {
      return clientWithAuth.post<ProcessMSKFormServiceResponse>(
        settings.MSK_API_BASE_URL + '/ptaif/process',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': settings.MSK_TC_ID_VALIDATION_API_KEY,
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
    },
    [clientWithAuth, idToken]
  )

  return {
    processMSKProviderForm,
  }
}
