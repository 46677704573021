import { createContext } from 'react'

import { BaseLayoutUIStore } from 'src/infrastructure'

const BaseLayoutUIContext = createContext<BaseLayoutUIStore>({
  showFullPageLoader: false,
  setShowFullPageLoader: () => {},
})
BaseLayoutUIContext.displayName = 'BaseLayoutUIContext'

export { BaseLayoutUIContext }
