import axios from 'axios'

import { PTCUseCases } from 'src/domain'
import {
  UsePTCAdapter,
  mapToSendSMSError,
  mapToSendSMSReturns,
  mapToSendSMSServicePayload,
  APIError,
} from 'src/adapters'
import { useSendSMSService, SendSMSServiceResponse } from 'src/infrastructure'

export const usePTCAdapter: UsePTCAdapter = () => {
  const sendSMSService = useSendSMSService()

  const sendSMS: PTCUseCases['sendSMS'] = async (args) => {
    try {
      const payload = mapToSendSMSServicePayload(args)
      const response = await sendSMSService.sendSMS(payload)
      return mapToSendSMSReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const sendSMSErrorResponse = error.response?.data
        if (sendSMSErrorResponse) {
          const sendSMSError = mapToSendSMSError(
            sendSMSErrorResponse as SendSMSServiceResponse
          )
          throw new APIError(sendSMSError.message)
        }
      }
      throw error
    }
  }

  return {
    sendSMS,
  }
}
