export const TC_PHONE = process.env.REACT_APP_TC_PHONE
export const TC_PHONE_DISPLAY = process.env.REACT_APP_TC_PHONE_DISPLAY

export const NODE_ENV = process.env.NODE_ENV

// MOCKS

export const MOCK_BACKEND = process.env.REACT_APP_MOCK_BACKEND == 'true'

export const MOCK_SEND_SMS_API =
  process.env.REACT_APP_MOCK_SEND_SMS_API == 'true'

export const MOCK_MSK_API = process.env.REACT_APP_MOCK_MSK_API == 'true'

export const SEND_SMS_API_BASE_URL = process.env.REACT_APP_SEND_SMS_API_BASE_URL

// MSK API

export const MSK_API_BASE_URL = process.env.REACT_APP_MSK_API_BASE_URL

export const MSK_TC_ID_VALIDATION_API_KEY =
  process.env.REACT_APP_MSK_TC_ID_VALIDATION_API_KEY

export const MSK_TC_ID_VALIDATION_BEARER_TOKEN =
  process.env.REACT_APP_MSK_TC_ID_VALIDATION_BEARER_TOKEN

// AWS

export const AWS_PROJECT_REGION = process.env.REACT_APP_AWS_REGION
export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_REGION

export const AWS_COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID

export const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID

export const AWS_USER_POOLS_WEB_CLIENT_ID =
  process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID

export const AWS_OAUTH_DOMAIN = process.env.REACT_APP_AWS_OAUTH_DOMAIN

export const AWS_OAUTH_SCOPE = process.env.REACT_APP_AWS_OAUTH_SCOPE?.split(' ')

export const AWS_OAUTH_REDIRECT_SIGN_IN =
  process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN

export const AWS_OAUTH_REDIRECT_SIGN_OUT =
  process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT

export const OKTA_HOSTED_SIGNIN =
  process.env.REACT_APP_OKTA_HOSTED_SIGNIN === 'true'
