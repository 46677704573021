import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
} from '@mui/material'

import { SelectFieldProps } from 'src/presentation'

export function SelectField<TMenuItem>({
  id,
  label,
  name,
  value,
  onChange,
  menuItems,
  getMenuItemKey,
  getMenuItemValue,
  getMenuItemLabel,
  error,
  helperText,
  ...selectProps
}: SelectFieldProps<TMenuItem>) {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`} error={error}>
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        error={error}
        {...selectProps}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={getMenuItemKey(menuItem)}
            value={getMenuItemValue(menuItem)}
          >
            {getMenuItemLabel(menuItem)}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}
